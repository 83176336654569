import React from 'react';
import { Layout } from '../../components/Layout';
import img1 from '../../images/articles/urw2.jpg';
import img2 from '../../images/articles/urw4.jpg';
import urw5 from '../../images/articles/urw5.jpg';
import pinkUrw from '../../images/articles/pink_urw.jpg';

const UtilityRightOfWays = () => (
  <Layout title="Utility Right of Ways" description="" keywords="">
    <main>
      <h1>Utility Right of Ways</h1>

      <div className="text-center my-4">
        <img src={img1} alt="Utility Right of Way" width={800} height={400} />
      </div>

      <p>
        A Utility Right of Way is a portion of land owned by one property owner in which rights of
        access are granted to another. Many parcels of land in Alberta have a Utility Right of Way
        (U.R.W.) on them. While there are good reasons for these to exist, more recently, many
        municipalities take a more aggressive approach to enforcing the rights associated with these
        portions of property and the rights associated with them. As a result of this rise in recent
        municipal enforcement, our clients are increasingly asking questions regarding utility right
        of ways: where they are, why they exist, and their impact on homeowners and real estate
        transactions.
      </p>

      <p className="mt-6">
        <strong>What are Utility Right of Ways (U.R.W.)?</strong>
      </p>

      <p>
        There is often a public need to install common infrastructure such as cable, telephone,
        water, sewage, and gas to service many homes in an area.In Alberta, the typical arrangement
        is to install utilities on private land. The mechanism used to allow this is by means of a
        right of way agreement registered on the land title. This lays out what rights are created,
        who has these rights, and the location of these rights. The location can be defined three
        ways:registered on a plan, written into a description on the title, or through a blanket
        registration on the entirety of the property.The following are examples and descriptions of
        each:
      </p>

      <p>
        <strong>Registered on a Plan</strong>
        <br />
        The current standard, and most common way, to define the location of the rights is through a
        plan registered by an Alberta Land Surveyor for this purpose at the Alberta Land Titles
        office. This plan will clearly, graphically, indicate the size and location of the right of
        way on the parcel.
      </p>
      <div className="text-center my-4">
        <img src={img2} alt="Utility Right of Way" width={800} height={400} />
      </div>

      <p>
        <strong>Description on Title</strong>
        <br />
        The second way the location may be referenced, seen on older right of ways, is by a
        description on the title such as “the south 25 feet” of the parcel.
      </p>
      <div className="text-center my-4">
        <img src={pinkUrw} alt="URW Description on Title" width={800} height={100} />
      </div>

      <p>
        <strong>Blanket Registration</strong>
        <br />
        The third way, used almost exclusively on rural properties with gas cooperatives, is known
        as a blanket registration. With a blanket registration the entire parcel is subject to the
        right of way, and the rights allow access to the entire property regardless of the utilities
        location.
      </p>
      <div className="text-center my-4">
        <img src={urw5} alt="URW Description on Title" width={800} height={162} />
      </div>

      <p>
        <strong>Where are they located? </strong>
      </p>
      <p>
        The most common location and size for the right of way in new communities is the front 3.50
        metres (11.5 feet) of the property. In older areas, 3.05 metres (10 feet) is common. Corner
        lots often have a smaller right of way running down the side of the parcel (property). Other
        types of right of ways can exist, and will be linked in a new post, when it becomes
        available.
      </p>

      <p>
        <strong>Why do they exist?</strong>
      </p>
      <p>
        It should be made clear that the land subject to the right of way is still part of the
        parcel owned by the landowner. Despite this, the right of way grants rights to others for
        some use of the land. Most utility right of ways limit rights to subsurface rights, but with
        stipulations on what can be built above. Namely there are rights to install, upgrade and
        maintain services mentioned above but the agreement may also additionally note that
        structures will not be allowed to be built on the right of way area.{' '}
      </p>

      <p>
        <strong>What is their impact on land owners?</strong>
      </p>
      <p>
        The most common interaction however most people will have with right of ways is when they
        sell their home and require a Real Property Report (R.P.R.). An important aspect of utility
        right of ways to appreciate is that, from a land surveying / Real Property Report (RPR)
        perspective, the concern is where the legal right of way is located, not where the utility
        infrastructure is in fact located. One current example of how these right of ways impact
        land owners is the recent push for internet service companies to upgrade their cable and
        telephone lines to fibre optic for increased service speeds; the right of the service
        provider to enter and do construction on private property is derived from the utility right
        of way document registered on the parcel.
      </p>
      <p>
        It can also be the case that the utilities are installed outside the right of way, and a
        portion of the service is almost always branched off of the main line to service the
        building on the parcel. These ‘branches’ are not typically covered under the right of way
        agreement, as it is solely for the use of that particular land owner, not for the public as
        a whole accessing the service line. Such branches are not indicated on a Real Property
        Report or indicated on a registered plan. Therefore, knowing the location of the legal right
        of way is NOT a replacement for having the actual utilities located for the purpose of
        excavation (e.g. installing fence posts, landscaping, etc.). If you need to know the
        locations of utilities for construction, please reach out to:
      </p>
      <p>
        <a href="http://www.clickbeforeyoudig.com/" target="cbyd">
          Click before you dig
        </a>
        <br />
        or
        <br />
        <a href="https://utilitysafety.ca/" target="aoc">
          Utility Safety Partners
        </a>{' '}
        (Previously known as Alberta One Call).
      </p>

      <p>
        <strong>
          How does this affect my Real Property Report (RPR) and possible real estate transactions?
        </strong>
      </p>
      <p>
        It is a requirement of Real Property Reports ( RPR’s) used for real estate transactions to
        show any and all encroachments associated with a property. Encroachments are any structures
        which interfere with the access to the right of way. Plant life or non permanent structures
        (e.g. patio furniture, moveable planters, etc.) may not be shown. While an exhaustive list
        of structures that could be shown is impossible, the most common, and most problematic, are
        retaining walls.{' '}
      </p>
      <p>
        The municipalities surrounding Calgary (Chestermere, Cochrane, Airdrie, etc.) have their own
        enforcement policies for encroachments. The City of Calgary now requires either an
        encroachment consent letter or an encroachment agreement for essentially all encroachments.
        The typical total cost (including the Surveyor, City and Land Titles fee, if needed) to have
        a surveyor process the needed approvals to allow the encroachments to remain range anywhere
        from $50 - $1500.{' '}
      </p>
      <p>
        The part our clients find most frustrating, however, is the significant timelines for
        processing such agreements. The encroachments department at the City of Calgary is currently
        taking about 9 weeks just to let you know what agreement is required to allow the
        encroachments to remain, and about an additional 4-6 or more months to execute once given
        the go ahead. This is longer than most buyers/sellers are willing to wait. The typical
        solution is for the real estate transaction to go through as usual, but with what is called
        a holdback of some funds from the sale, usually about $5,000-$10,000. Rarely, but sometimes,
        the “worst case” costs are associated with having to remove the encroachment. This money
        will be held in trust until the RPR receives the necessary approval.
      </p>
      <p>
        When encroachments exist, homeowners can expect increased costs, and substantial increased
        delays after the sale of the property. A homeowner should consider the impact of the
        encroachments, and evaluate options. It might make more sense to remove the offending
        encroachment, this is a conversation you should have with both your lawyer and Alberta Land
        Surveyor. We are here to help you navigate these situations.
      </p>
    </main>
  </Layout>
);

export default UtilityRightOfWays;
